.internas{
	padding-bottom: 40px;

	.pag-header{
		background-color: #dbdcde;
		padding: 25px 0;
		margin-bottom: 40px;

		ul{
			list-style-type: none;
			margin-bottom: 0;
			padding-left: 0;
			font-family: $nts-eb-medium;
			font-size: 18px;
			line-height: 1.1;
			color: #777;

			h1{
				font-size: 18px;
				color: $laranja;
				display: inline-block;
			}
		}

		@include media-breakpoint-up(md) {
			ul{
				display: flex;
				align-items: center;
				justify-content: flex-end;
				flex-wrap: wrap;

				li + li{
					margin-left: 5px;
					padding-left: 7px;
					position: relative;

					&:before{
						content: '';
						position: absolute;
						left: 0;
						top: 50%;
						transform: translateY(-60%);
						width: 2px;
						height: 16px;
						background-color: #777;
					}
				}
			}
		}
		@include media-breakpoint-down(sm) {
			ul{
				text-align: center;
				li+ li{
					margin-top: 5px;
				}
			}
		}
	}
	// .pag-header

	.paginacao{
		display: flex;
		align-items: center;
		justify-content: center;
		flex-wrap: wrap;
		list-style-type: none;
		margin-bottom: 0;
		padding-left: 0;

		li + li{
			margin-left: 10px;
		}

		li{

			&.active a{
				background-color: $laranja;
				color: #fff;
			}

			a{
				width: 26px;
				height: 26px;
				border-radius: 50%;
				background-color: #dbdcde;
				font-size: 14px;
				line-height: 1;
				color: #777;
				display: flex;
				align-items: center;
				justify-content: center;
				font-family: $mts-bold;
				transition: all 0.3s linear;

				&:hover{
					background-color: $laranja;
					color: #fff;
				}

				&.icone{
					font-size: 10px;
				}
			}
		}
	}

}
// .internas

.pag-sobre-nos{
	.titulo-pag{
		color: #777;
		font-size: 34px;
		font-family: $nts-eb-black;
		line-height: 1.1;
		margin-bottom: 20px;
	}
	.botao-voltar{
		margin-top: 30px;
		text-align: center;
	}
	@include media-breakpoint-down(md) {
		.galeria-de-fotos{
			margin-top: 30px;
		}
		.titulo-pag{
			text-align: center;
		}
	}
}
// .pag-sobre-nos

.pag-como-comprar{
	.titulo-pag{
		color: #777;
		font-size: 20px;
		font-family: $nts-medium;
		line-height: 1.1;
		margin-bottom: 30px;

		@include media-breakpoint-down(sm) {
			text-align: center;
		}
	}
	.row{
		.passo{
			margin-bottom: 5px;
			color: #777;
			font-size: 28px;
			font-family: $nts-bold;

			span{
				color: $laranja;
			}
		}

		@include media-breakpoint-down(sm) {
			text-align: center;
			figure.col-md-3{
				margin-bottom: 15px;
				.lazyimage{
					margin-left: auto;
					margin-right: auto;
				}
			}
		}
	}
	.row + .row{
		margin-top: 30px;
	}
	.botao-voltar{
		margin-top: 40px;
		text-align: center;
	}
}
// .pag-como-comprar

.pag-clientes{
	.titulo-pag{
		color: #777;
		font-size: 34px;
		font-family: $nts-eb-black;
		line-height: 1.1;
		margin-bottom: 20px;
	}

	.owl-carousel{
		margin-top: 40px;
		.owl-nav{
			position: absolute;
			width: 100%;
			top: 50%;
			left: 50%;
			transform: translate(-50%, -50%);

			.owl-prev,
			.owl-next{
				position: absolute;
				top: 0;
				transform: translateY(-50%);
				color: #fff;
				font-size: 24px;
				line-height: 1;
				text-shadow: 0 0 6px rgba(0,0,0,0.4);
				transition: all 0.2s linear;

				&:hover{
					color: $laranja;
				}
			}
			.owl-prev{
				left: 0;
			}
			.owl-next{
				right: 0;
			}
		}
	}
	.botao-voltar{
		margin-top: 40px;
		text-align: center;
	}
}
// .pag-clientes

.pag-materia-prima{
	.titulo-pag{
		color: #777;
		font-size: 26px;
		font-family: $nts-eb-black;
		line-height: 1.1;
		margin-bottom: 30px;
		text-align: center;
	}
	.botao-voltar{
		margin-top: 40px;
		text-align: center;
	}
}
// .pag-materia-prima

.pag-contato{
	padding-bottom: 0;
	.titulo-pag{
		color: $laranja;
		font-size: 18px;
		margin-bottom: 30px;
		font-family: $nts-bold;
		text-align: center;
	}
	.desc.editor{
		margin-bottom: 30px;
	}
	.btn-padrao{
		margin: 30px auto 0 auto;
	}
	#mapa-contato{
		padding-bottom: percentage(450/1920);
		min-height: 300px;
		margin-top: 40px;
	}
}
// .pag-contato

.pag-produtos{
	@include media-breakpoint-up(lg) {
		.btn-aside{
			display: none;
		}
	}
	@include media-breakpoint-down(md) {
		.bg-aside{
			position: fixed;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			z-index: 999;
			display: none;
			background-color: rgba(0,0,0,0.6);
		}
		.pag-header{
			margin-bottom: 0;
		}
		.btn-aside{
			width: 100%;
			background-color: $laranja;
			text-align: center;
			padding: 15px 10px;
			font-family: $nts-bold;
			font-size: 14px;
			color: #fff !important;
			line-height: 1;
			display: block;
			margin-bottom: 30px;
			cursor: pointer;
		}
	}

	.card-produtos{
		margin-bottom: 30px;
	}

	#asideFiltro{
		line-height: 1.2;

		ul{
			list-style-type: none;
			margin-bottom: 0;
			padding-left: 0;
		}

		.titulo-aside{
			width: 100%;
			background-color: $laranja;
			text-align: center;
			padding: 10px;
			font-family: $nts-bold;
			font-size: 14px;
			color: #fff;
			line-height: 1;
		}

		.conteudo-aside{
			color: #333;

			.subtitulo{
				font-size: 20px;
				font-family: $nts-eb;
			}

			.group-categorias{
				.nome-cat{
					cursor: pointer;
					font-family: $nts-medium;
				}
				.group-sub{
					a{
						display: block;
					}
				}
			}
			.filtros{
				font-size: 14px;
				.filtro-group{
					input{
						position: fixed;
						bottom: 100%;
					}
					input:checked ~ label.item{
						color: $laranja;

						.circle{
							background-color: $laranja;
						}

					}
					.item{
						display: flex;
						align-items: center;
						cursor: pointer;
						color: #333;


						&:hover{
							color: $laranja;
						}

						.circle{
							width: 12px;
							height: 12px;
							background-color: #ddd;
							display: inline-block;
							border-radius: 50%;
							margin-right: 5px;
							transition: all 0.3s linear;
							margin-bottom: 3px;
						}
					}
				}
			}
		}

		@include media-breakpoint-up(lg) {
			border: 1px solid #ededed;

			.conteudo-aside{
				padding: 0 10px 30px 10px;

				.subtitulo{
					margin-top: 30px;
					margin-bottom: 5px;
				}

				.group-categorias{
					.nome-cat{
						font-size: 16px;
						margin-bottom: 5px;

						&:hover,
						.active{
							color: $laranja;
						}
					}
					.nome-cat[aria-expanded="true"] {
						color: $laranja;
					}
					.categoria + .categoria{
						margin-top: 5px;
					}
					.group-sub{
						a{
							padding: 2px 5px;
							font-size: 15px;
							&:hover{
								color: $laranja;
							}
						}
					}
				}
			}
		}
		//up-lg

		@include media-breakpoint-down(md) {
			position: fixed;
			top: 0;
			left: -250px;
			background-color: #fff;
			width: 250px;
			height: 100%;
			overflow-y: auto;
			padding-bottom: 10px;
			z-index: 1000;
			border-right: 1px solid #000;
			transition: all 0.4s ease-out;

			&.active{
				left: 0;
			}

			.conteudo-aside{
				.subtitulo{
					padding: 10px 5px 7px 5px;
					background-color: #ccc;
					text-align: center;
					line-height: 1;
				}
				.group-categorias{
					.nome-cat{
						background-color: #ddd;
						padding: 10px 5px;
						transition: all 0.2s linear;

						&:hover,
						.active{
							background-color: $laranja;
							color: #fff;
						}
					}
					.nome-cat[aria-expanded="true"] {
						background-color: $laranja;
						color: #fff;
					}
					.group-sub{
						a{
							display: block;
							padding: 10px;
							background-color: #ededed;

							&:hover{
								background-color: #ccc;
							}
						}
					}
				}
				.filtros{
					padding: 10px 5px 0 5px;
					font-size: 16px;

					label{
						padding: 5px 0;
					}
				}
			}
		}
	}
	// #asideFiltro
}
// .pag-produtos

.pag-produtos-detalhes{

	.nav-tabs{
		margin-bottom: 5px;
		border: none;
		li + li{
			margin-left: 5px;
		}
		a{
			padding: 10px;
			background-color: #dbdcde;
			display: block;
			color: #777;
			font-family: $nts-eb;
			font-size: 16px;
			line-height: 1;

			&.active{
				background-color: $laranja;
				color: #fff;
			}
		}
	}
	.tab-content{
		.video-produtos{
			padding-bottom: percentage(720/1280);
		}
		.carousel{
			padding-bottom: 40px;
			.controles{
				position: absolute;
				width: 30px;
				bottom: 0;
				left: 50%;
				transform: translateX(-50%);

				.prev,
				.next{
					position: absolute;
					bottom: 0;
					color: #777;
					font-size: 24px;
					line-height: 1;
					transition: all 0.2s linear;

					&:hover{
						color: $laranja;
					}
				}
				.prev{
					left: 0;
				}
				.next{
					right: 0;
				}
			}
		}
	}

	.compartilhar-produto{
		display: flex;
		align-items: center;
		justify-content: center;
		margin-top: 10px;

		a + a{
			margin-left: 10px;
		}

		a{
			width: 30px;
			height: 30px;
			display: flex;
			align-items: center;
			justify-content: center;
			background-color: #ddd;
			color: #555;
			border-radius: 50%;
			font-size: 16px;
			transition: all 0.2s linear;

			&:hover{
				background-color: $laranja;
				color: #fff;
			}
		}
	}
	// .compartilhar-produto

	.titulo-pag{
		color: $laranja;
		font-size: 18px;
		line-height: 1.3;
		font-family: $nts-bold;
		margin-bottom: 5px;
	}
	.codigo{
		font-size: 14px;
		color: $cinza;
		line-height: 1.3;
		margin-bottom: 15px;
	}

	.conteudo-descritivo{

		.form-estilos{
			padding-bottom: 20px;
			margin-bottom: 20px;
			border-bottom: 1px solid #dbdcde;

			.group{
				margin-top: 20px;
				.valor{
					color: $laranja;
					font-size: 14px;

					span{
						font-family: $nts-bold;
					}
				}
			}
			// .group
		}
		// .form-estilos

		.titulo-desc{
			font-family: $nts-bold;
			font-size: 18px;
			color: $cinza;
			margin-bottom: 15px;
		}

		@include media-breakpoint-only(xl) {
			padding: 30px;
		}
		@include media-breakpoint-only(lg) {
			padding: 15px;

		}
		@include media-breakpoint-up(lg) {
			border: 1px solid #dbdcde;
		}
		@include media-breakpoint-down(md) {
			.titulo-pag,
			.codigo,
			.valor,
			.titulo-desc{
				text-align: center;
			}
			.titulo-pag{
				margin-top: 20px;
			}
		}
		@include media-breakpoint-up(sm) {
			.form-estilos{
				.col-sm-6:nth-child(odd) {
					padding-right: 5px;
				}
				.col-sm-6:nth-child(even) {
					padding-left: 5px;
				}
				.group{
					display: flex;
					align-items: center;
					justify-content: space-between;
					flex-wrap: wrap;
				}
			}
		}
		@include media-breakpoint-only(xs) {

			.form-estilos{
				.btn-padrao{
					margin: 10px auto 0 auto;
				}
			}
		}
	}
	// .conteudo-descritivo

	.botao-voltar{
		margin-top: 30px;

		.btn-padrao{
			width: 180px;
			height: 40px;
			display: flex;
			align-items: center;
			justify-content: center;
			margin: 0 auto;
		}
	}
}
//.pag-produtos-detalhes

.pag-carrinho{
	.botao-add-mais{
		margin-bottom: 30px;
		i.fas{
			margin-right: 5px;
		}
	}

	.cabecalho-carrinho-up-lg{
		background-color: $laranja;
		display: flex;
		align-items: center;
		justify-content: space-between;
		font-size: 14px;
		color: #fff;
		font-family: $nts-medium;

		@include media-breakpoint-up(lg) {
			padding: 5px 30px 5px 25px;
		}
		@include media-breakpoint-down(md) {
			text-align: center;
			padding: 10px;
			span{
				display: none;
			}
		}
	}

	@include media-breakpoint-up(lg) {
		.item-carrinho + .item-carrinho{
			border-top: 2px solid #ddd;
		}
	}
	.item-carrinho{
		font-size: 14px;
		color: #777;
		line-height: 1.3;
		position: relative;

		.botao-retirar{
			position: absolute;
			width: 24px;
			height: 24px;
			display: flex;
			align-items: center;
			justify-content: center;
			background-color: $laranja;
			color: #000;
			border-radius: 50%;
			z-index: 2;
			font-size: 10px;
			top: 8px;
			left: 8px;

			&:hover{
				background-color: lighten($laranja, 5%);
			}
		}

		.img{
			width: 100%;
			max-width: 100px;
			min-width: 100px;
		}

		.nome{
			font-family: $nts-eb-black;
		}

		.qtd-carrinho{
			display: flex;
			justify-content: center;

			.botao{
				width: 35px;
				height: 40px;
				display: flex;
				align-items: center;
				justify-content: center;
				font-size: 10px;
				color: #fff;
				background-color: $laranja;
				border: none;
				outline: none;

				&:hover{
					background-color: lighten($laranja, 5%);
				}
			}
			.input-number{
				border: none;
				border-top: 1px solid $laranja;
				border-bottom: 1px solid $laranja;
				text-align: center;
				background-color: #dbdcde;
				color: $cinza;
				font-size: 15px;
				font-family: $nts-eb;
				width: 50px;
				outline: none;
			}
		}

		@include media-breakpoint-up(lg) {
			display: flex;
			padding: 20px 0 20px 20px;

			.bloco-um{
				display: flex;
				width: 100%;
				max-width: 290px;
				min-width: 290px;

				.img{
					margin-right: 10px;
				}
			}
			.bloco-dois{
				flex-grow: 1;
			}
			.bloco-tres{
				display: flex;
				align-items: center;
			}
			.bloco-dois,
			.bloco-tres{
				margin-left: 15px;
				padding-left: 15px;
				border-left: 2px solid #ccc;
			}
		}
		@include media-breakpoint-down(md) {
			padding: 20px;
			border-left: 1px solid #ddd;
			border-right: 1px solid #ddd;
			border-bottom: 1px solid #ddd;

			.bloco-um{
				display: flex;
				.img{
					margin-right: 10px;
				}
			}
			.bloco-dois{
				margin: 15px 0;
				text-align: center;
			}
		}
	}
	//.item-carrinho

	.final-carrinho{
		margin-top: 20px;
		padding-top: 30px;
		border-top: 4px solid $laranja;
		text-align: center;

		.titulo-form{
			color: $laranja;
			font-family: $nts-bold;
			font-size: 18px;
		}

		.form-estilos{
			width: 100%;
			max-width: 920px;
			margin-left: auto;
			margin-right: auto;
			margin-top: 10px;

			.btn-padrao{
				margin: 20px auto 0 auto;
				background-color: $laranja;
				color: #fff;

				&:hover{
					background-color: lighten($laranja, 5%);
				}
			}

			@include media-breakpoint-up(sm) {
				.col-sm-6:nth-child(odd) {
					padding-right: 5px;
				}
				.col-sm-6:nth-child(even) {
					padding-left: 5px;
				}
			}
		}
		// .form-estilos
	}
	// .final-carrinho

}
// .pag-carrinho
