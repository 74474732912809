@import "drop__grid";
@import "main__nav";

.topo{
	z-index: 130;
	background-color: #fff;

	&.fx{
		@include media-breakpoint-only(xl) {
			.main-navigation{
				position: fixed;
				top: 0;
				left: 0;
				background-color: #fff;
				width: 100%;
				z-index: 900;
				box-shadow: 0 0 7px rgba(0,0,0,0.4);
				animation: fadeInDown 0.6s linear;
			}
		}
		@include media-breakpoint-down(lg) {
			.mbl-controls{
				position: fixed;
				top: 0;
				left: 0;
				background-color: #fff;
				width: 100%;
				z-index: 900;
				box-shadow: 0 0 7px rgba(0,0,0,0.4);
				animation: fadeInDown 0.6s linear;
			}
		}
	}

	ul{
		list-style-type: none;
		padding-left: 0;
		margin-bottom: 0;
	}

	.barra-topo{
		background-color: $laranja;
		color: #fff;
		font-family: $ubu-italic;
		line-height: 1;
		font-size: 14px;
		padding: 5px 0;

		.container{
			display: flex;
			align-items: center;
			justify-content: space-between;
		}
		.bem-vindo{
			flex-grow: 1;
		}

		.box-contato + .box-contato{
			margin-left: 10px;
		}
		.box-contato{
			display: flex;
			align-items: center;

			i.fas{
				width: 20px;
				height: 20px;
				display: flex;
				align-items: center;
				justify-content: center;
				background-color: #fff;
				color: $laranja;
				font-size: 10px;
				border-radius: 50%;
				margin-right: 5px;
			}

			a + a{
				margin-left: 5px;
				padding-left: 5px;
				border-left: 1px solid #fff;
			}
		}

		@include media-breakpoint-down(lg) {
			display: none;
		}
	}
	// .barra-topo

	.botoes-group{
		display: flex;
		align-items: center;

		.lupa,
		.carrinho{
			height: 30px;
			display: block;
			background-size: 100% 100%;
			background-repeat: no-repeat;
			background-position: center center;
			cursor: pointer;
		}
		.lupa{
			width: 30px;
			background-image: url('../images/lupa.png');
		}
		.carrinho{
			width: 32px;
			background-image: url('../images/cart.png');
		}

		.btn-carrinho{
			display: inline-flex;
			.qtd-carrinho{
				font-size: 15px;
				line-height: 1;
				font-family: $nts-eb;
				color: $cinza;
				background-color: #dbdcde;
				width: 30px;
				height: 30px;
				display: flex;
				align-items: center;
				justify-content: center;
				border-radius: 50%;
				margin-left: 5px;
				padding-top: 4px;
			}
		}

		.btn-menu,
		.btn-mbl{
			width: 30px;
			height: 30px;
			display: flex;
			align-items: center;
			justify-content: center;
			cursor: pointer;
			line-height: 1;
			font-size: 20px;
			cursor: pointer;

			.hamburguer{
				position: relative;
				display: block;
				background: $cinza;
				width: 29px;
				height: 5px;
				transition: .4s linear;

				&:before,
				&:after{
					background: $cinza;
					content: '';
					display: block;
					width: 100%;
					height: 100%;
					position: absolute;
					transition: .4s linear;
				}
				&:before{
					top: -9px;
				}
				&:after{
					bottom: -9px;
				}
			}
			&.active{
				.hamburguer {
		            background-color: transparent;
		            &:before {
                        transform: rotate(45deg);
		                top: 0;
		            }
		            &:after {
                        transform: rotate(-45deg);
		                bottom: 0;
		            }
				}
			}
		}
	}
	// .botoes-group

	.main-navigation{

		.categorias-destaques{
			.dropdown{
				.btn-categoria{
					color: $cinza;
					font-family: $nts-eb;
					line-height: 1;

					.mask-icon{
						width: 40px;
						height: 40px;
						display: block;
						mask-position: center center;
						mask-size: 100% 100%;
						background-color: $cinza;
					}
				}

				.drop-content{
					.menu-subcategoria{
						a{
							font-family: $nts-medium;
							font-size: 15px;
							line-height: 1.3;
							text-transform: uppercase;
							color: #777;
							display: block;
						}
					}
					.produto-destaque{
						font-size: 14px;
						.box{
							.nome{
								font-family: $nts-eb;
								color: $cinza;
								line-height: 1.3;
							}
							.valor{
								color: $laranja;
								span{
									font-family: $nts-eb;
								}
							}
							.btn-padrao{
								min-width: 125px;
							}
						}
					}
					// .produto-destaque
				}
				//.drop-content
			}
			// .dropdown
		}
		// .categorias-destaques

		.main-menu{
			.menu{
				a{
					font-family: $nts-medium;
					font-size: 15px;
					line-height: 1.3;
					text-transform: uppercase;
					color: #777;
					display: block;
				}
			}
		}
		// .main-menu
		.box-informacoes{
			.item{
				font-size: 15px;
				line-height: 1.2;
				color: $cinza;

				a + a{
					padding-left: 5px;
					margin-left: 5px;
					position: relative;

					&:before{
						content: '';
						position: absolute;
						left: 0;
						top: 50%;
						transform: translateY(-60%);
						background-color: $cinza;
						width: 1px;
						height: 12px;
					}
				}
			}
			.redes{
				margin-top: 15px;
				.chamada{
					font-size: 15px;
					line-height: 1.2;
					color: $cinza;
					font-family: $nts-bold;
				}
				.group{
					display: flex;
					align-items: center;
					flex-wrap: wrap;
					a + a{
						margin-left: 15px;
					}
					a{
						width: 30px;
						height: 30px;
						display: flex;
						align-items: center;
						justify-content: center;
						background-color: #ddd;
						color: #555;
						border-radius: 50%;
						font-size: 16px;
						transition: all 0.2s linear;

						&:hover{
							background-color: $laranja;
							color: #fff;
						}
					}
				}
			}
		}

		@include media-breakpoint-only(xl) {
			background-color: #fff;

			.meu-container{
				width: 100%;
				max-width: 1140px;
				padding-right: 15px;
				padding-left: 15px;
				margin-right: auto;
				margin-left: auto;
				display: flex;
				justify-content: space-between;
				position: relative;
			}
			//meu-container

			.logo{
				padding: 9px 0;
			}

			.categorias-destaques{
				display: flex;
				flex-grow: 1;
				margin-left: 115px;
				.dropdown{
					position: relative;
					display: flex;

					&:after{
						content: '';
						display: block;
						position: relative;
						background-color: #ededed;
						margin: auto 15px;
						width: 2px;
						height: 50px;
					}

					&:hover{
						.btn-categoria{
							color: $laranja;
							.mask-icon{
								background-color: $laranja;
							}
						}
						.drop-content{
							display: flex;
						}
					}

					.btn-categoria{
						display: flex;
						align-items: center;
						padding: 10px;

						.mask-icon{
							pointer-events: none;
							margin-right: 8px;
							min-width: 40px;
						}

						.expandir{
							display: none;
						}
					}

					.drop-content{
						position: absolute;
						background-color: #fff;
						padding: 30px 20px;
						top: 100%;
						left: -100px;
						min-width: 560px;
						max-width: 560px;
						width: 100%;
						z-index: 10;
						box-shadow: 0 2px 6px rgba(0,0,0,0.4);
						border-bottom: 4px solid $laranja;

						&:before{
							content: '';
							position: absolute;
							width: 15px;
							height: 15px;
							border-radius: 50%;
							background-color: $laranja;
							z-index: 1;
							top: -7px;
							left: 200px;
						}

						.menu-subcategoria{
							width: 100%;
							text-align: right;

							li + li{
								margin-top: 10px;
							}

							a{
								display: block;
								transition: all 0.2s linear;
								position: relative;

								&:after{
									content: '';
									position: absolute;
									top: 50%;
									transform: translateY(-50%);
									right: 0;
									width: 0px;
									height: 2px;
									background-color: #777;
									transition: all 0.2s linear;
								}

								&:hover{
									padding-right: 35px;
									color: $laranja;

									&:after{
										width: 25px;
										background-color: $laranja;
									}
								}
							}
						}

						.produto-destaque{
							display: flex;
							text-align: center;
							padding-left: 10px;
							margin-left: 10px;
							border-left: 2px solid #dbdcde;
							width: 100%;
							max-width: 320px;
							min-width: 320px;

							.img{
								margin-right: 10px;
								min-width: 160px;
							}
							.box{
								.valor{
									margin: 20px 0;
								}
							}
						}
						// .produto-destaque
					}
					//.drop-content
				}
			}
			// .categorias-destaques

			.botoes-group{
				.btn-carrinho{
					margin: 0 50px 0 30px;
				}
			}

			.main-menu{
				position: absolute;
				top: 100%;
				right: 0;
				background-color: #fff;
				z-index: 12;
				width: 100%;
				max-width: 270px;
				min-width: 270px;
				box-shadow: 0 2px 6px rgba(0,0,0,0.4);
				padding: 30px 35px 30px 20px;
				display: none;
				opacity: 0;

				&.active{
					animation: fadeIn 0.4s linear;
					display: block;
					opacity: 1;
				}

				.menu{
					text-align: right;
					margin-bottom: 30px;
					padding-bottom: 30px;
					border-bottom: 2px solid #ddd;

					li + li{
						margin-top: 10px;
					}
					.active a{
						padding-right: 35px;
						color: $laranja;
						&:after{
							width: 25px;
							background-color: $laranja;
						}
					}

					a{
						display: block;
						transition: all 0.2s linear;
						position: relative;

						&:after{
							content: '';
							position: absolute;
							top: 50%;
							transform: translateY(-50%);
							right: 0;
							width: 0px;
							height: 2px;
							background-color: #777;
							transition: all 0.2s linear;
						}

						&:hover{
							padding-right: 35px;
							color: $laranja;

							&:after{
								width: 25px;
								background-color: $laranja;
							}
						}
					}
				}
				.box-informacoes{
					.item + .item{
						margin-top: 15px;
					}
					.item{
						text-align: right;
					}
					.redes{
						text-align: right;
						.chamada{
							margin-bottom: 10px;
						}
						.group{
							justify-content: flex-end;
						}
					}
				}
			}
			.box-informacoes.only-responsivo{
				display: none;
			}

		}
		//DESKTOP

		@include media-breakpoint-down(lg) {
			position: fixed;
			top: 0;
			left: -250px;
			z-index: 1000;
			width: 250px;
			height: 100%;
			overflow-y: auto;
			background-color: #fff;
			transition: all 0.4s ease-out;
			border-right: 1px solid #000;
			padding-bottom: 10px;

			&.active{
				left: 0;
			}

			.meu-container{
				display: flex;
				flex-direction: column;

				.logo{
					text-align: center;
					padding: 10px;
					margin-bottom: 10px;
					order: 1;
				}
				.main-menu{
					display: block;
					order: 2;

					.menu{
						.active a{
							background-color: $laranja;
							color: #fff;
						}
						a{
							display: block;
							padding: 12px 5px;
							font-family: $nts-eb;
							font-size: 16px;

							&:hover{
								background-color: $laranja;
								color: #fff;
							}
						}
					}

					.box-informacoes{
						display: none;
					}
				}
				.categorias-destaques{
					order: 3;

					.dropdown{

						&:hover{
							.btn-categoria{
								background-color: $laranja;
								color: #fff;

								.mask-icon{
									background-color: #fff;
								}
							}
						}

						.btn-categoria{
							position: relative;
							display: flex;
							align-items: center;
							padding: 4px 45px 4px 5px;


							&:hover{
								background-color: $laranja;
								color: #fff;

								.mask-icon{
									background-color: #fff;
								}
							}

							.mask-icon{
								width: 30px;
								min-width: 30px;
								height: 30px;
								margin: 3px 5px;
							}

							.expandir{
								position: absolute;
								width: 40px;
								height: 100%;
								right: 0;
								top: 0;
								cursor: pointer;

								&:before,
								&:after{
									content: '';
									position: absolute;
									top: 50%;
									left: 50%;
									transform: translate(-50%, -50%);
									background-color: currentColor;
								}
								&:before{
									width: 15px;
									height: 3px;
								}
								&:after{
									width: 3px;
									height: 15px;
									transition: height 0.3s linear;
								}
							}
							.expandir[aria-expanded="true"] {
								&:after{
									height: 0;
								}
							}
						}
						.drop-content{
							background-color: #ededed;
							.menu-subcategoria{
								text-align: center;
								a{
									display: block;
									padding: 10px 5px;

									&:hover{
										background-color: #ddd;
									}
								}
							}
							.produto-destaque{
								text-align: center;
								padding: 10px;
								.img{
									margin-bottom: 10px;
								}
								.valor{
									margin: 5px 0;
								}
							}
						}
					}

				}
				//.categorias-destaques
				.box-informacoes.only-responsivo{
					order: 4;
					text-align: center;
					.item{
						margin-top: 10px;
					}
					.redes{
						.group{
							justify-content: center;
							margin-top: 10px;
						}
					}
				}
				.botoes-group{
					display: none;
				}
			}
		}
		//DOWN MD

	}
	// .main-navigation

	.campo-busca{
		position: fixed;
		top: 0;
		left: 50%;
		transform: translate(-50%, calc(-100% - 50px));
		z-index: 1000;
		width: 100%;
		max-width: 570px;
		padding: 0 15px;
		text-align: center;
		transition: all 0.4s ease-out;
		opacity: 1;

		&.active{
			top: 50%;
			transform: translate(-50%, -50%);
		}

		label{
			font-family: $nts-medium;
			font-size: 18px;
			color: $cinza;
			line-height: 1;
			margin-bottom: 10px;
		}
		.group{
			position: relative;
			.form-control{
				background-color: transparent;
				border: none;
				border-bottom: 1px solid #ddd;
				color: #777;
				font-size: 14px;
				font-family: $ubu-italic;
				height: 40px;
				border-radius: 0;
				transition: all 0.2s linear;

				&::placeholder{
					color: #777;
				}
				&:focus{
					box-shadow: none;
					border-color: $laranja;
				}
			}
			button.fas{
				position: absolute;
				top: 50%;
				transform: translateY(-50%);
				right: 10px;
				outline: none;
				background-color: transparent;
				border: none;
				color: #000;
				font-size: 26px;
				transition: all 0.2s linear;

				&:hover{
					color: $laranja;
				}
			}
		}
	}
	// .campo-busca
	.bg-busca{
		position: fixed;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		z-index: 999;
		display: none;
		background-color: rgba(255,255,255,0.95);
	}

	.mbl-controls{
		padding: 10px 0;
		background-color: #fff;

		.container{
			display: flex;
			align-items: center;
			justify-content: space-between;
			flex-wrap: wrap;
		}

		.botoes-group{
			.btn-carrinho{
				margin: 0 40px;
			}
		}

		@include media-breakpoint-between(md, lg) {
			.logo.down-sm{
				display: none;
			}
		}
		@include media-breakpoint-down(sm) {
			.logo.between-md-lg{
				display: none;
			}
		}
		@include media-breakpoint-only(xl) {
			display: none;
		}
	}

}
// .topo
