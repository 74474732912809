@font-face {
    font-family: 'noto_sanscondensed';
    font-display: auto;
    src: url('#{$fonts}/notosans-condensed/notosans-condensed-webfont.eot');
    src: url('#{$fonts}/notosans-condensed/notosans-condensed-webfont.eot?#iefix') format('embedded-opentype'),
         url('#{$fonts}/notosans-condensed/notosans-condensed-webfont.woff2') format('woff2'),
         url('#{$fonts}/notosans-condensed/notosans-condensed-webfont.woff') format('woff'),
         url('#{$fonts}/notosans-condensed/notosans-condensed-webfont.ttf') format('truetype'),
         url('#{$fonts}/notosans-condensed/notosans-condensed-webfont.svg#noto_sanscondensed') format('svg');
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: 'noto_sanscondensed_bold';
    font-display: auto;
    src: url('#{$fonts}/notosans-condensedbold/notosans-condensedbold-webfont.eot');
    src: url('#{$fonts}/notosans-condensedbold/notosans-condensedbold-webfont.eot?#iefix') format('embedded-opentype'),
         url('#{$fonts}/notosans-condensedbold/notosans-condensedbold-webfont.woff2') format('woff2'),
         url('#{$fonts}/notosans-condensedbold/notosans-condensedbold-webfont.woff') format('woff'),
         url('#{$fonts}/notosans-condensedbold/notosans-condensedbold-webfont.ttf') format('truetype'),
         url('#{$fonts}/notosans-condensedbold/notosans-condensedbold-webfont.svg#noto_sanscondensed_bold') format('svg');
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: 'noto_sanscondensed_medium';
    font-display: auto;
    src: url('#{$fonts}/notosans-condensedmedium/notosans-condensedmedium-webfont.eot');
    src: url('#{$fonts}/notosans-condensedmedium/notosans-condensedmedium-webfont.eot?#iefix') format('embedded-opentype'),
         url('#{$fonts}/notosans-condensedmedium/notosans-condensedmedium-webfont.woff2') format('woff2'),
         url('#{$fonts}/notosans-condensedmedium/notosans-condensedmedium-webfont.woff') format('woff'),
         url('#{$fonts}/notosans-condensedmedium/notosans-condensedmedium-webfont.ttf') format('truetype'),
         url('#{$fonts}/notosans-condensedmedium/notosans-condensedmedium-webfont.svg#noto_sanscondensed_medium') format('svg');
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: 'noto_sanscondensed_extrabold';
    font-display: auto;
    src: url('#{$fonts}/notosans-condensedextrabold/notosans-condensedextrabold-webfont.eot');
    src: url('#{$fonts}/notosans-condensedextrabold/notosans-condensedextrabold-webfont.eot?#iefix') format('embedded-opentype'),
         url('#{$fonts}/notosans-condensedextrabold/notosans-condensedextrabold-webfont.woff2') format('woff2'),
         url('#{$fonts}/notosans-condensedextrabold/notosans-condensedextrabold-webfont.woff') format('woff'),
         url('#{$fonts}/notosans-condensedextrabold/notosans-condensedextrabold-webfont.ttf') format('truetype'),
         url('#{$fonts}/notosans-condensedextrabold/notosans-condensedextrabold-webfont.svg#noto_sanscondensed_extrabold') format('svg');
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: 'noto_sansextracondensed_black';
    font-display: auto;
    src: url('#{$fonts}/notosans-extracondensedblack/notosans-extracondensedblack-webfont.eot');
    src: url('#{$fonts}/notosans-extracondensedblack/notosans-extracondensedblack-webfont.eot?#iefix') format('embedded-opentype'),
         url('#{$fonts}/notosans-extracondensedblack/notosans-extracondensedblack-webfont.woff2') format('woff2'),
         url('#{$fonts}/notosans-extracondensedblack/notosans-extracondensedblack-webfont.woff') format('woff'),
         url('#{$fonts}/notosans-extracondensedblack/notosans-extracondensedblack-webfont.ttf') format('truetype'),
         url('#{$fonts}/notosans-extracondensedblack/notosans-extracondensedblack-webfont.svg#noto_sansextracondensed_black') format('svg');
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: 'noto_sansextracondensed_medium';
    font-display: auto;
    src: url('#{$fonts}/notosans-extracondensedmedium/notosans-extracondensedmedium-webfont.eot');
    src: url('#{$fonts}/notosans-extracondensedmedium/notosans-extracondensedmedium-webfont.eot?#iefix') format('embedded-opentype'),
         url('#{$fonts}/notosans-extracondensedmedium/notosans-extracondensedmedium-webfont.woff2') format('woff2'),
         url('#{$fonts}/notosans-extracondensedmedium/notosans-extracondensedmedium-webfont.woff') format('woff'),
         url('#{$fonts}/notosans-extracondensedmedium/notosans-extracondensedmedium-webfont.ttf') format('truetype'),
         url('#{$fonts}/notosans-extracondensedmedium/notosans-extracondensedmedium-webfont.svg#noto_sansXCnMd') format('svg');
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: 'open_sanslight';
    font-display: auto;
    src: url('#{$fonts}/opensans-light/opensans-light-webfont.eot');
    src: url('#{$fonts}/opensans-light/opensans-light-webfont.eot?#iefix') format('embedded-opentype'),
         url('#{$fonts}/opensans-light/opensans-light-webfont.woff2') format('woff2'),
         url('#{$fonts}/opensans-light/opensans-light-webfont.woff') format('woff'),
         url('#{$fonts}/opensans-light/opensans-light-webfont.ttf') format('truetype'),
         url('#{$fonts}/opensans-light/opensans-light-webfont.svg#open_sanslight') format('svg');
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: 'ubuntuitalic';
    font-display: auto;
    src: url('#{$fonts}/ubuntu-italic/ubuntu-italic-webfont.eot');
    src: url('#{$fonts}/ubuntu-italic/ubuntu-italic-webfont.eot?#iefix') format('embedded-opentype'),
         url('#{$fonts}/ubuntu-italic/ubuntu-italic-webfont.woff2') format('woff2'),
         url('#{$fonts}/ubuntu-italic/ubuntu-italic-webfont.woff') format('woff'),
         url('#{$fonts}/ubuntu-italic/ubuntu-italic-webfont.ttf') format('truetype'),
         url('#{$fonts}/ubuntu-italic/ubuntu-italic-webfont.svg#ubuntuitalic') format('svg');
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: 'montserratbold';
    font-display: auto;
    src: url('#{$fonts}/montserrat-bold/montserrat-bold-webfont.eot');
    src: url('#{$fonts}/montserrat-bold/montserrat-bold-webfont.eot?#iefix') format('embedded-opentype'),
         url('#{$fonts}/montserrat-bold/montserrat-bold-webfont.woff2') format('woff2'),
         url('#{$fonts}/montserrat-bold/montserrat-bold-webfont.woff') format('woff'),
         url('#{$fonts}/montserrat-bold/montserrat-bold-webfont.ttf') format('truetype'),
         url('#{$fonts}/montserrat-bold/montserrat-bold-webfont.svg#montserratbold') format('svg');
    font-weight: normal;
    font-style: normal;
}
