.modal{
	.modal-dialog{
		max-width: 570px;
		padding: 15px;
	}
	.modal-content{
		background-color: transparent;
		border: none;
		border-radius: 0;
		.modal-topo{
			border: none;
			padding: 0;
			background-color: transparent;
			padding: 5px;
			display: flex;
			justify-content: flex-end;
			button{
				padding: 0;
				opacity: 1;
				position: relative;
				display: block;
				font-family: $nts-eb-medium;
				font-size: 18px;
				color: $laranja;
				background-color: transparent;
				border: none;
				outline: none;
				display: flex;
				align-items: center;

				i.fas{
					width: 30px;
					height: 30px;
					display: flex;
					align-items: center;
					justify-content: center;
					border-radius: 50%;
					background-color: $laranja;
					color: #000;
					font-size: 12px;
					line-height: 1;
					margin-left: 5px;
				}
			}
		}
		.modal-body{
			background-color: #dbdcde;
			text-align: center;

			.icone{
				margin: 0 auto 30px auto;
				width: 82px;
				height: 81px;
				background-image: url('../images/check.png');
				background-repeat: no-repeat;
				background-position: center center;
				background-size: 100% 100%;
			}

			.texto{
				color: #777;
				line-height: 1.1;
				font-family: $nts-eb-medium;

				span{
					font-family: $nts-eb-black;
				}
			}

			.btn-padrao{
				margin-top: 30px;
				background-color: $laranja;
				color: #fff;
			}

			@include media-breakpoint-up(md) {
				padding: 50px;
				.texto{
					font-size: 36px;
				}
			}
			@include media-breakpoint-down(sm) {
				padding: 30px 15px;
				.texto{
					font-size: 24px;
				}
			}
		}
	}
}
