.drop__item__icon{
	display: block;
	flex-shrink: 0;
	width: 40px;
	height: 40px;
	background-color: #DDD;
	background-position: center center;
	border-radius: 4px;
	margin-right: 10px;
	overflow: hidden;
}

.drop__grid__link{
	display: flex;
	font-family: "noto_sanscondensed_extrabold";
	font-size: 14px;
	color: #777;

	.text{
		display: block;
	}
}

.card__dropdown{

	.card__dropdown__title{
		font-family: "noto_sanscondensed_extrabold";
		font-size: 14px;
		color: #777;
		margin-bottom: 10px;
	}

	.card__dropdown__price{
		color: #dc853d;
		margin-bottom: 10px;

		strong,
		span{
			display: block;
		}
	}

	.btn-card-dropdown{
		font-family: "noto_sanscondensed_extrabold";
		background: #dbdcde;
		color: #777;
		width: 100%;
		border-radius: 2rem;

		&:hover{
			background: #dc853d;
			color: #FFF;
		}
	}
}

.card__area{
	background-color: #CCC;
	background-position: center center;
	background-size: cover;
	position: relative;

	&.loading{
		background-image: none !important;
		pointer-events: none;
		overflow: hidden;

		&::before{
			content:'';
			width: 30%;
			height: 100%;
			position: absolute;
			left: 0;
			top: 0;
			transform: translateX(-100%);
			background: linear-gradient(to right, transparent, rgba(#fff,.5), transparent);
			animation: squeleton 1s linear infinite;
		}

		.card__dropdown__image{
			align-self: flex-start;
			background: #CCC;

			img{
				opacity: 0;
			}
		}

		.card__dropdown__title,
		.content__editable,
		.card__dropdown__price{
			color: transparent;
			background-image: linear-gradient(
				to bottom,
				transparent 0,
				transparent .25em,
				#CCC .25em,
				#CCC 1.25em,
				transparent 1.25em,
				transparent 1.5em
			);
			background-size: 100% 1.5em;
		}

		.card__dropdown__price{
			background-size: 80% 1.5em;
			background-repeat: repeat-y;
			background-position: center top;
		}

		.btn{
			background: #CCC !important;
			border-color: #CCC !important;
			color: transparent !important;
		}
	}
}

.card__area__content{
	background: #FFF;
}

@include media-breakpoint-down(md) {
	.drop__grid{
		background: rgba(#000,.1);
	}

	.card__area{
		padding: 15px;
	}

	.card__area__content{
		padding: 15px;
	}

	.categoria__side{

		& + .categoria__side{
			border-top: 1px solid #CCC;
		}

		.btn{
			.fas{
				transition: transform .3s linear;
				display: inline-block;
			}

			&[aria-expanded="true"] {
				.fas{
					transform: rotate(-90deg);
				}
			}
		}
	}

	.drop__grid__list{
		padding-left: 0;
		margin-bottom: 10px;
		list-style: none;
		border-top: 1px solid rgba(#000,.1);
		max-height: calc(100vh - 280px);
		overflow: auto;

		li + li{
			border-top: 1px solid rgba(#000,.1);
		}
	}

	.drop__grid__link{
		padding: 10px 15px;
	}

	.card__dropdown{
		padding: 15px;
		display: grid;
		grid-template-columns: repeat(auto-fit, minmax(160px, 1fr));
		grid-column-gap: 15px;
		border-top: 1px solid rgba(#000,.1);
		text-align: center;

		.card__dropdown__image{
			margin-bottom: 10px;
		}
	}
}

@include media-breakpoint-up(lg) {
	.drop__grid{
		background: #FFF;
		border: 1px solid #CCC;
		padding: 20px;
		width: 100%;
		box-shadow: 0 0 8px rgba(#000,.5);
		position: relative;
		margin-bottom: 30px;
		display: grid;
		grid-template-columns: repeat(3, minmax(200px, calc(
			(100% - 60px) / 3
		)));
		grid-column-gap: 30px;

		&::after{
			content:'';
			position: absolute;
			height: calc(100% - 40px);
			border: 1px solid #CCC;
			top: 20px;
			right: 33.333%;
		}
	}

	.drop__grid.show{
		display: grid;
		grid-template-columns: repeat(3, minmax(200px, 1fr));
		grid-column-gap: 30px;
	}

	.drop__grid__list{
		grid-column: span 2;
		display: grid;
		grid-template-columns: repeat(2, 1fr);
		grid-column-gap: 30px;
		grid-row-gap: 10px;
		max-height: calc(100vh - 200px);
		overflow: auto;
		list-style: none;
		padding-left: 0;
		background-image: linear-gradient(to bottom, #CCC, #CCC);
		background-position: center top;
		background-repeat: repeat-y;
		background-size: 2px 100%;

		li{
			display: block;
		}
	}

	.card__dropdown{
		display: grid;
		grid-template-columns: repeat(2, calc(50% - 5px));
		grid-gap: 10px;
		text-align: center;
		margin-bottom: 30px;
	}

	.drop__grid__link{
		padding: 5px;
		border-radius: 5px;
	}

	li.active .drop__grid__link {
		background: #DDD;
	}

	.card__area{
		padding: 20px;
		margin: -20px -20px -20px 0;
	}

	.card__area__content{
		background: #FFF;
		padding: 10px;
		box-shadow: 0 0 8px rgba(#000,.5);
	}
}


@keyframes squeleton{
	0% {
		transform: translateX(-100%);
		left: 0;
	}
	100% {
		transform: translateX(0);
		left: 100%;
	}
}
