.galeria-de-fotos{
	.img-foto-destaque{
		margin-bottom: 20px;
	}

	.img-foto{
		width: 167px;
		max-width: 100%;
		margin-left: auto;
		margin-right: auto;

		figure{
			overflow: hidden;
			position: relative;
			cursor: pointer;

			&:before{
				content: '';
				position: absolute;
				top: 0;
				left: 0;
				width: 100%;
				height: 100%;
				background-color: rgba(220,133,61,0.5);
				z-index: 1;
				opacity: 0;
				transition: all 0.2s linear;
			}
		}

		&:hover{
			figure{
				&:before{
					opacity: 1;
				}
			}
		}
	}

	.owl-carousel{
		padding-bottom: 40px;
		.owl-nav{
			position: absolute;
			width: 30px;
			bottom: 0;
			left: 50%;
			transform: translateX(-50%);

			.owl-prev,
			.owl-next{
				position: absolute;
				bottom: 0;
				color: #777;
				font-size: 24px;
				line-height: 1;
				transition: all 0.2s linear;

				&:hover{
					color: $laranja;
				}
			}
			.owl-prev{
				left: 0;
			}
			.owl-next{
				right: 0;
			}
		}
	}
}
