body{
	font-family: $nts-regular;
}
body,
html{
	width: 100%;
	min-height: 100vh;
}
#app{
	overflow: hidden;
	min-height: 100vh;
	display: flex;
	flex-direction: column;

	.wrapper{
		flex-grow: 1;

	}
}
textarea{
	resize: none;
}
figure, h1, h2, h3, h4, h5, h6, p{
	margin: 0;
}
a{
	color: inherit;

	&:focus,
	&:hover{
		color: inherit;
		text-decoration: none;
	}
}
*[data-animate] {
	opacity: 0;
}
.animated[data-animate] {
	opacity: 1;
}
.bg-menu{
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	z-index: 999;
	display: none;
	background-color: rgba(0,0,0,0.6);
}
.desc{
	font-size: 14px;
	color: #777;

	img,
	iframe{
		max-width: 100%;
	}

	a:hover{
		text-decoration: underline;
	}

	&.editor{
		p + p{
			margin-top: 1em;
		}
	}
}
