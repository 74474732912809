.card-produtos{
	max-width: 255px;
	width: 100%;
	margin-left: auto;
	margin-right: auto;

	.img{
		position: relative;
		overflow: hidden;

		img{
			transition: all 1.5s cubic-bezier(0, 0, 0.2, 1);
		}

		&:before{
			content: '';
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			z-index: 1;
			background-color: rgba(0,0,0,0.5);
			transition: all 0.3s linear;
			opacity: 0;
		}
		&:after{
			content: '+';
			position: absolute;
			top: 50%;
			left: 50%;
			transform: translate(-50%, 200%);
			width: 30px;
			height: 30px;
			z-index: 2;
			border-radius: 50%;
			background-color: $laranja;
			color: #fff;
			display: flex;
			align-items: center;
			justify-content: center;
			font-family: $mts-bold;
			font-size: 24px;
			line-height: 1;
			transition: all 0.3s linear;
			opacity: 0;
		}

	}
	.conteudo-produto{
		text-align: center;
		padding: 10px;
		border: 1px solid #ddd;
		font-size: 14px;
		line-height: 17px;
		color: #777;

		.nome,
		.descricao{
			height: 34px;
			@include line-clamp(2);
		}

		.nome{
			font-family: $nts-bold;
			font-size: 14px;
		}
		.descricao{
			margin: 10px 0;
		}
		.valor{
			color: $laranja;
			span{
				font-family: $nts-bold;
			}
		}
		.btn-padrao{
			width: 170px;
			max-width: 100%;
			margin-top: 10px;
			line-height: 1;
		}
	}

	&:hover{
		.img{
			img{
				transform: scale3d(1.1, 1.1, 1.1);
			}
			&:before{
				opacity: 1;
			}
			&:after{
				transform: translate(-50%, -50%);
				opacity: 1;
			}
		}
		.conteudo-produto{
			.btn-padrao{
				background-color: $laranja;
				color: #fff;
			}
		}
	}
}
// .card-produtos

.card-materias{
	max-width: 255px;
	width: 100%;
	margin-left: auto;
	margin-right: auto;
	margin-bottom: 30px;

	.conteudo-materia{
		text-align: center;
		padding: 10px;
		border: 1px solid #ddd;
		font-size: 14px;
		line-height: 17px;
		color: #777;

		.nome{
			font-family: $nts-bold;
			margin-bottom: 5px;
			transition: all 0.2s linear;
			font-size: 14px;
			max-height: 34px;
			@include line-clamp(2);
		}
		.descricao{
			max-height: 85px;
			@include line-clamp(5);
		}
	}

	&:hover{
		.conteudo-materia{
			.nome{
				color: $laranja;
			}
		}
	}
}
// .card-materias
