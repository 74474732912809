.btn-pill{
	border-radius: 2rem;
}
.btn-padrao{
	background-color: #dbdcde;
	color: $cinza;
	font-size: 14px;
	line-height: 1;
	text-align: center;
	padding: 13px 10px;
	font-family: $nts-eb;
	text-transform: uppercase;
	display: inline-block;
	border-radius: 20px;
	transition: all 0.3s linear;

	&:hover{
		background-color: $laranja;
		color: #fff;
	}
}

.btn-orange{
    @include button-variant(#dc853d,#dc853d);
    color: #FFF !important;
}

#btn-whats{
    z-index: 500;
    position: fixed;
    bottom: 15px;
    left: 15px;
    background-color: #4CAF50;
    font-size: 24px;
    color: #fff;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 54px;
    height: 54px;
    box-shadow: 0 0 16px rgba(0,0,0,0.5);
}

#btn-whats:after{
    content: '';
    position: absolute;
    z-index: -1;
    top: calc(50% - 5px);
    left: calc(50% - 5px);
    width: 10px;
    height: 10px;
    background-color: rgba(76,175,80,1);
    border-radius: 50%;
    opacity: 0;
    animation: infiniteRipple 1.5s infinite;
}
@keyframes infiniteRipple {
    0%{
        transform: scale(0);
        opacity: 1;
    }
    100% {
        transform: scale(10);
        opacity: 0;
    }
}

.btn__laranja{
	@include button-variant($laranja, $laranja);
}

.btn__pill{
	padding: 0.5rem 2rem;
	border-radius: 2rem;
	font-family: $nts-bold;

	&,
	&:hover{
		color: #FFF;
	}
}

[data-dismiss="alerta"] > * {
	pointer-events: none;
}
