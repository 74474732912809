.produtos__banner{
	padding-top: 30px;
	padding-bottom: 30px;

	@include media-breakpoint-down(sm) {
		display: flex;
		flex-direction: column;

		.carousel{
			order: 1;
		}

		.categorias__side{
			order: 2;
		}
	}

	@include media-breakpoint-up(xl) {

		.categoria__side__header{
			a{
				position: relative;
				padding-right: 40px !important;

				&::before{
					content: fa-content($fa-var-chevron-right);
					font-family: 'Font Awesome 5 Free';
					font-weight: 900;
					right: 15px;
					top: 50%;
					transform: translateY(-50%);
					position: absolute;
				}
			}

			.btn{
				display: none;
			}
		}

		.carousel{
			grid-column: span 9;
		}

	}

	.categoria__side + .categoria__side{
		border-top: 1px solid #CCC;
	}
}

.categorias__side{
	.categorias__side__title{
		padding: 10px 15px;
		background: #dc853d;
		font-size: 16px;
		line-height: 1.5;
		color: #FFF;
		text-align: center;
		font-family: noto_sanscondensed_extrabold;
	}

	.categorias__side__list{
		border-left: 1px solid #CCC;
		border-right: 1px solid #CCC;
		border-bottom: 1px solid #CCC;
		list-style: none;
		padding-left: 0;
	}

	.categoria__side__header{
		display: flex;

		a{
			display: block;
			padding: 10px 15px;
			flex-grow: 1;
		}
	}

	.categoria__side{
		position: relative;
	}
}

.banners{
	max-width: 1920px;
	margin-right: auto;
	margin-left: auto;
	overflow: hidden;

	.controles{
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
		z-index: 2;

		.prev,
		.next{
			color: #fff;
			font-size: 24px;
			line-height: 1;
			position: absolute;
			top: 0;
			transform: translateY(-50%);
			text-shadow: 0 0 6px rgba(0,0,0,0.4);
			transition: all 0.2s linear;

			&:hover{
				color: $laranja;
			}
		}
		.prev{
			left: 0;
		}
		.next{
			right: 0;
		}
	}
	@include media-breakpoint-only(xl) {
		.controles{
			width: 1110px;
		}
	}
	@include media-breakpoint-only(lg) {
		.controles{
			width: 930px;
		}
	}
	@include media-breakpoint-only(md) {
		.controles{
			width: 690px;
		}
	}
	@include media-breakpoint-only(sm) {
		.controles{
			width: 510px;
		}
	}
	@include media-breakpoint-only(xs) {
		.controles{
			width: 90%;
		}
	}
}

.exibicao-produtos{
	&.destaques{
		padding: 40px 0;
	}
	&.mais-vistos{
		padding-bottom: 40px;
	}

	.header-section{
		margin-bottom: 30px;
		padding-bottom: 20px;
		border-bottom: 2px solid #ddd;

		.titulo-sec{
			color: $laranja;
			line-height: 1.2;
			text-transform: uppercase;
			font-family: $nts-eb-medium;

			span{
				font-family: $nts-eb-black;
			}
		}
		.categorias-sec{
			list-style-type: none;
			margin-bottom: 0;
			padding-left: 0;
			display: flex;
			align-items: center;
			flex-wrap: wrap;

			.active a{
				background-color: $laranja;
				color: #fff;
			}
			a{
				height: 38px;
				border-radius: 19px;
				background-color: transparent;

				&:hover{
					background-color: $laranja;
				}
			}
		}

		@include media-breakpoint-up(lg) {
			.titulo-sec{
				font-size: 36px;
			}
		}
		@include media-breakpoint-up(md) {
			display: flex;
			align-items: center;
			justify-content: space-between;

			.categorias-sec{


				li + li{
					position: relative;
					margin-left: 10px;
					padding-left: 10px;

					&:before{
						content: '';
						position: absolute;
						width: 2px;
						height: 15px;
						background-color: #ddd;
						display: block;
						top: 50%;
						transform: translateY(-50%);
						left: 0;
					}
				}
			}
		}
		@include media-breakpoint-between(sm, md) {
			.titulo-sec{
				font-size: 30px;
			}
		}
		@include media-breakpoint-down(sm) {
			text-align: center;
			.titulo-sec{
				margin-bottom: 10px;
			}
			.categorias-sec{
				justify-content: center;
				a{
					background-color: #eee;
					margin: 7px 5px;
				}
			}
		}
		@include media-breakpoint-only(xs) {
			.titulo-sec{
				font-size: 24px;
			}
		}
	}
	// .header-section

	.owl-carousel{
		.owl-nav{
			position: absolute;
			width: 100%;
			top: 30%;
			left: 50%;
			transform: translate(-50%, -50%);

			.owl-prev,
			.owl-next{
				position: absolute;
				top: 0;
				transform: translateY(-50%);
				color: #fff;
				font-size: 24px;
				line-height: 1;
				text-shadow: 0 0 6px rgba(0,0,0,0.4);
				transition: all 0.2s linear;

				&:hover{
					color: $laranja;
				}
			}
			.owl-prev{
				left: 0;
			}
			.owl-next{
				right: 0;
			}

			@include media-breakpoint-only(xs) {
				max-width: 300px;

				.owl-prev,
				.owl-next{
					font-size: 34px;
					color: #eee;
				}
			}
		}
	}

	.botao{
		margin-top: 40px;
		text-align: center;
		.btn-padrao{
			width: 178px;
			max-width: 100%;
		}
	}
}
// .exibicao-produtos

.box-ofertas{
	background-color: #dbdcde;
	.segura-conteudo{
		position: relative;

		.titulo{
			color: $laranja;
			line-height: 1.2;
			text-transform: uppercase;
			font-family: $nts-bold;
			font-size: 24px;
		}
		.desc{
			margin: 15px 0;
		}
		.btn-padrao{
			background-color: $laranja;
			color: #fff;
			min-width: 180px;

			&:hover{
				background-color: darken($laranja, 10%);
			}
		}
	}

	@include media-breakpoint-only(xl) {
		.segura-conteudo{
			width: calc(50% - calc(100vw - 1155px)/2);
		}
	}
	@include media-breakpoint-only(lg) {
		.segura-conteudo{
			width: calc(50% - calc(100vw - 975px)/2);
		}
	}
	@include media-breakpoint-up(lg) {
		.segura-conteudo{

			min-height: 450px;
			padding: 15px 230px 15px 15px;
			display: flex;
			justify-content: center;
			flex-direction: column;

			.titulo-down-md{
				display: none;
			}

			&:before{
				content: '';
				position: absolute;
				width: 217px;
				height: 450px;
				top: 50%;
				transform: translateY(-50%);
				right: 0;
				background-size: 100% 100%;
				background-position: center center;
				background-repeat: no-repeat;
				background-image: url('../images/ofertas.png');
			}
		}
		.segura-imagem{
			width: 50%;
			display: flex;
		}
	}
	@include media-breakpoint-down(md) {
		.segura-conteudo{
			width: 100%;
			margin-left: auto;
			margin-right: auto;
			padding: 0px 15px 30px 15px;
			text-align: center;

			.titulo-down-md{
				background-color: $laranja;
				text-transform: uppercase;
				padding: 8px 5px 5px 5px;
				display: block;
				color: #fff;
				font-family: $nts-bold;
				font-size: 26px;
				line-height: 1;
				margin-bottom: 30px;
				z-index: 1;
				position: relative;

				&:before{
					content: '';
					position: absolute;
					z-index: -1;
					background-color: $laranja;
					width: 100vw;
					height: 100%;
					top: 0;
					left: 50%;
					transform: translateX(-50%);
				}
			}
		}
		.segura-imagem{
			width: 100%;
			img{
				max-width: 100%;
				height: auto;
			}
		}
	}
	@include media-breakpoint-only(md) {
		.segura-conteudo{
			max-width: 720px;
		}
	}
	@include media-breakpoint-only(sm) {
		.segura-conteudo{
			max-width: 540px;
		}
	}
}
// .box-ofertas
