.alerta{
	width: 100%;
	max-width: 538px;
	margin-left: auto;
	margin-right: auto;
}

.alerta__header{
	text-align: right;

	.btn{
		color: #F08736;
		font-family: $nts-bold;

		.fas{
			display: inline-flex;
			width: 1.5em;
			height: 1.5em;
			align-items: center;
			justify-content: center;
			border-radius: 50%;
			background: #F08736;
			color: #000;

			&:before{
				font-size: 0.8em;
				vertical-align: middle;
				line-height: 1;
			}
		}
	}
}

.alerta__body{
	background: #DCDDDF;
	text-align: center;
	padding: 60px;
}

.alerta__icon{
	width: 2em;
	height: 2em;
	font-size: 2rem;
	margin-right: auto;
	margin-left: auto;
	margin-bottom: 2rem;
	color: #F28739;
	position: relative;
	display: flex;
	align-items: center;
	justify-content: center;

	&:before{
		content:'';
		border: toRem(7) solid currentColor;
		border-radius: 50%;
		position: absolute;
		width: 100%;
		height: 100%;
		left: 0;
		top: 0;
	}

	&:after{
		font-family: 'Font Awesome 5 Free';
		font-weight: 900;
		font-size: toRem(40);
	}
}

.alerta__icon--success{
	&:before{
		clip-path: polygon(50% 50%, 100% 50%, 100% 100%, 0 100%, 0 0, 75% 0);
	}

	&:after{
		content:fa-content($fa-var-check);
		transform: translate(#{toRem(5)}, #{toRem(-5)});
	}
}


.alerta__icon--error{
	&:after{
		content:fa-content($fa-var-times);
	}
}

.alerta__message{
	margin-bottom: 2rem;
	color: #787878;

	strong{
		font-family: $nts-eb-black;
	}
}
