.form-estilos{

	.form-group{
		margin-bottom: 10px;
	}

	input.form-control,
	select.form-control{
		height: 40px;
	}

	select.form-control{
		-webkit-appearance: none;  /* Remove estilo padrão do Chrome */
		-moz-appearance: none; /* Remove estilo padrão do FireFox */
		appearance: none; /* Remove estilo padrão do FireFox*/
		background: url('../images/select.png') no-repeat #ededed;  /* Imagem de fundo (Seta) */
		background-position: 95% center;  /*Posição da imagem do background*/
	}

	.form-control{
		background-color: #ededed;
		border: 1px solid transparent;
		border-radius: 20px;
		color: #8d8d8d;
		font-size: 14px;
		font-family: $nts-bold;

		&:focus{
			border-color: $laranja;
			box-shadow: none;
		}
		&::placeholder{
			color: #8d8d8d;
		}
	}

	button.btn-padrao{
		width: 180px;
		max-width: 100%;
		display: flex;
		align-items: center;
		justify-content: center;
		flex-direction: column;
		height: 40px;
		border: none;
		outline: none;
		line-height: 1;
		position: relative;

		div.spinner-border{
			display: none;
		}

		&.carregar{
			color: transparent !important;
			pointer-events: none;

			.spinner-border{
				display: block;
				color: #fff;
				position: absolute;
				top: calc(50% - 15px);
				left: calc(50% - 15px);
			}
		}
	}

}
// .form-estilos
