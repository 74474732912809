.main__nav{
	a{
		font-family: "noto_sanscondensed_extrabold";
		font-size: 14px;
	}

	li{
		position: relative;
	}
}


@include media-breakpoint-up(xl) {
	.main__nav{
		list-style: none;
		display: flex;
		flex-grow: 1;
		margin-left: auto;
		padding-left: 30px !important;
		margin-right: auto;
		padding-right: 30px !important;
		justify-content: center;

		& > li {
			display: flex;

			& + li::before{
				content:'';
				width: 2px;
				height: 50px;
				flex-shrink: 0;
				background: #ededed;
				margin: auto 10px;
			}
		}

		a{
			color: #777;
			display: flex;
			align-items: center;
			justify-content: center;
			text-align: center;
			transition: background .3s linear, color .3s linear;
			padding: 10px 5px;
			white-space: nowrap;

			&:hover{
				color: #dc853d;
				text-decoration: none;
			}
		}

		.active a{
			color: #dc853d;
		}
	}
}

@include media-breakpoint-down(lg) {
	.main__nav{
		order: 2;

		a{
			display: block;
			padding: 10px 15px;
		}

		.active a{
			background: #dc853d;
			color: color-contrast(#dc853d);
		}
	}
}
