// Margins
@include margin-variant(15);
@include margin-variant(30);
@include margin-variant(40);
@include margin-variant(60);
@include margin-variant(70);

[data-owl]{

	&:not(.ready){
		display: none;
	}

	&.disabled{
		cursor: not-allowed;
		opacity: 0.6;
	}
}
#loading-page{
	background: #dbdcde;
	width: 100vw;
	height: 100vh;
	max-width: 100%;
	position: fixed;
	top: 0;
	left: 0;
	z-index: 2000;

	.progress{
	  height: 15px;
	  background: #dbdcde;
	  position: sticky;
	  top:0;
	  z-index: 9000;

	  .bar{
	  	width: calc(var(--progress) * 1%);
	  	height: 100%;
	  	background: $laranja;
	  	position: absolute;
	  	left: 0;
	  	top: 0;
	  	transition: width 0.2s linear;
	  }
	}
}

.alert-contato{
	width: 100%;
	max-width: 600px;
	margin-left: auto;
	margin-right: auto;
	margin-bottom: 40px;
	background: $success;
	border-color: $success;
	color: #FFF;
	text-align: center;
}