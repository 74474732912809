.rodape{
	padding-top: 20px;
	padding-bottom: 10px;
	background-image: url('../images/bg-rodape.jpg');
	font-size: 14px;
	color: #fff;
	line-height: 1.3;

	.logo{
		text-align: center;
		margin-bottom: 30px;
	}

	.botao{
		color: $laranja;
		font-family: $nts-bold;

		&:hover{
			text-decoration: underline;
		}
	}

	.item{
		a + a{
			padding-left: 5px;
			margin-left: 5px;
			position: relative;

			&:before{
				content: '';
				position: absolute;
				left: 0;
				top: 50%;
				transform: translateY(-60%);
				background-color: #fff;
				width: 1px;
				height: 12px;
			}
		}
	}
	.redes{
		text-align: center;
		margin-top: 20px;
		.titulo{
			color: $laranja;
			margin-bottom: 10px;
		}
		.group{
			display: flex;
			align-items: center;
			justify-content: center;
			flex-wrap: wrap;

			a + a{
				margin-left: 15px;
			}
			a{
				width: 28px;
				height: 28px;
				display: flex;
				align-items: center;
				justify-content: center;
				background-color: #ddd;
				color: #555;
				border-radius: 50%;
				font-size: 14px;
				transition: all 0.2s linear;

				&:hover{
					background-color: $laranja;
				}
			}
		}
	}

	.autorais{
		border-top: 1px solid rgba(255,255,255,0.1);
		margin-top: 20px;
		padding-top: 10px;
		font-family: $ops-light;
		font-size: 10px;

		a{
			display: inline-flex;
			align-items: center;
			margin-top: 5px;

			img{
				margin-left: 5px;
			}
		}
	}

	@include media-breakpoint-up(lg) {
		.texto-rodape{
			text-align: right;
		}
		.item:nth-child(2){
			margin-bottom: 15px;
		}
		.col-lg-6 + .col-lg-6{
			position: relative;

			&:before{
				content: '';
				position: absolute;
				left: 0;
				top: 0;
				width: 2px;
				height: 100%;
				background-color: rgba(255,255,255,0.1);
			}
		}
	}
	@include media-breakpoint-down(md) {
		text-align: center;
		.item{
			margin-top: 10px;
		}
	}
}
